

@keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .transitionBox {
    animation: slideInFromLeft 1s ease-in-out;
  }

  @keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .right {
    animation: slideInFromRight 1s ease-in-out; /* Adjust duration and timing function as needed */
  }
  

  .imgbounce{
    animation: bounce 0.5s; 
            animation-direction: alternate; 
            animation-timing-function: cubic-bezier(.5, 0.05, 1, .5); 
            animation-iteration-count: infinite;
  }

  @keyframes bounce { 
    from { 
        transform: translate3d(0, 0, 0); 
    } 
    to { 
        transform: translate3d(0, 50px, 0); 
    } 
} 

  .imgbounce { 
    -webkit-animation-name: bounce; 
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite; 
} 


/* .TextAnimation {

  animation: zoom-in-zoom-out 5s ease infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.3, 1.3);
  }
  100% {
    transform: scale(1, 1);
  }
} */

.TextAnimation1 {
  animation: MoveUpDown 2s linear infinite;
  position:static;
  /* left: 0; */
  /* bottom: 0; */
}

@keyframes MoveUpDown {
  0%, 100% {
    bottom: 0;
  }
  50% {
    bottom: 100px;
  }
}

@media (max-width: 600px) {
.responsive-image {
    margin-left: 0 !important;
  }
}



@keyframes mymove {
  from {left: 0px;}
  to {left: 200px;}
}


/*  (SOP-68) Web Page - Features - Content Changes (style for carousel) - TS - start */
@keyframes moveSideToSide {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(100%);
  }
  /* 100% {
    transform: translateX(0);
  } */
}

.block {
  width: 100%;
  height: 100%;
  /* background-color: blue; */
  position: absolute;
  animation: moveSideToSide 10s infinite;
}

.carousel {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}


/* 
@keyframes slide-left-to-right {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.carousel-item {
  animation: slide-left-to-right 0.5s ease-in-out;
} */


.carousel-slide-enter {
  transform: translateX(100%);
}

.carousel-slide-enter-active {
  transform: translateX(0);
  transition: transform 500ms ease-in-out;
}

.carousel-slide-exit {
  transform: translateX(0);
}

.carousel-slide-exit-active {
  transform: translateX(-100%);
  transition: transform 500ms ease-in-out;
}
/*  (SOP-68) Web Page - Features - Content Changes (style for carousel) - TS - start */