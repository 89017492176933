.FooterBg {
    margin: auto;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    background: linear-gradient(315deg,
    #184D69 3%, #A2D3DA 38%,
    #31AFA2 68%, #2BA8D9 98%);
    animation: gradient 15s ease infinite;
    background-size: 400% 400%;
    background-attachment: fixed;
}

@keyframes gradient {
    0% {
        background-position: 100% 100%;
    }
    50% {
        background-position: 0% 0%;
    }
    100% {
        background-position: 0% 0%;
    }
}


